#ag-canvas {
  height: 100%;
}

.ag-item {
  height: 100%;
  width: 100%;
}

#ag-resize-container {
  background-image: none!important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none!important;
}

.video-container {
  display: flex;
  height: 100%;
}

.video-canvas {
  display: none;
  align-items: center;
  justify-items: center;
  position: relative;
  height: 100%;
  z-index: 0;
}

.video-canvas.is-host-joined {
  display: flex;
}

.video-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 50;
}

.video-header-timer {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 0;
  width: 380px;
  border-bottom-left-radius: 16px;
}

.video-header-presenter {
  display: flex;
  position: relative;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  min-width: 350px;
  border-bottom-right-radius: 16px;
}

.device-modal {
  display: none;
  position: absolute;
  background-color: white;
  padding: 20px;
  padding-bottom: 0;
  left: 0;
  bottom: 65px;
  width: 330px;
  flex-direction: column;
}

.device-modal.active {
  display: flex;
}

.attendee-grid-settings {
  display: none;
  position: absolute;
  background-color: white;
  left: 20px;
  top: 37px;
  width: auto;
  flex-direction: column;
}

.attendee-grid-settings div {
  padding: 5px 30px;
}

.attendee-grid-settings div:hover {
  background-color: #E6E6E6;
}

.attendee-grid-settings.active {
  display: flex;
}

.video-header-settings {
  cursor: pointer;
  display: flex;
  border-right: 2px solid #cccccc;
  padding-right: 10px;
  align-items: center;
}

.video-header-settings i {
  font-size: 12px;
  margin-left: 5px;
}

.video-header-presenter-user {
  font-size: 16px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.video-header-presenter-user-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.video-header-presenter-user-name {
  font-weight: 500;
}

.video-header-timer-item {
  position: relative;
  display: flex;
  text-align: center;
}

.video-header-timer-item-pointer {
  cursor: pointer;
}

.attendee-count {
  top: -6px;
  left: 26px;
  position: absolute;
  font-size: 15px;
}

.video-header-timer-item-title {
  font-size: 14px;
  font-weight: 500;
  color: rgb(117, 117, 117);
}

.video-header-timer-item-value .timer-alert {
  color: red;
  font-weight: 500;
}

.video-header-timer-item-value.inactive-icon {
  color: #BEBEBE;
}

.video-header-timer-item-value {
  font-size: 26px;
  font-weight: 400;
  color: rgb(117, 117, 117);
}

/* button group */

.video-control-btn-group {
  height: 65px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 11;
  justify-content: space-between;
  align-items: center;
  color: black;
  display: flex;
}

.video-control-btn-group:hover,
.video-control-btn-group.show-contols:hover {
  display: flex;
}

.video-control-btn-group.show-contols {
  display: flex;
}

.video-control-btn-group .video-control-btn-group-name,
.video-control-btn-group .video-control-btn-group-controls,
.video-control-btn-group .video-control-btn-group-actions {
  display: flex;
  flex: 1;
}

.video-control-btn-group .video-control-btn-group-name {
  justify-content: flex-start;
}

.video-control-btn-group-name {
  padding-left: 20px;
  font-weight: 500;
  cursor: pointer;
}

.event-detail-modal {
  position: absolute;
  bottom: 50px;
  width: 400px;
}

.video-control-btn-group .video-control-btn-group-controls {
  justify-content: center;
}

.video-control-btn-group .video-control-btn-group-controls button{
  margin: 0 10px;
}


.video-control-btn-group .video-control-btn-group-actions {
  justify-content: flex-end;
}

.video-control-btn {
  background-color: white;
  color: rgb(45, 45, 45);
  width: 45px;
  height: 45px;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 22px;
  margin: 10px;
}

.video-control-btn.participant-btn {
  width: 36px;
  height: 36px;
  font-size: 18px;
  border-radius: 50%;
  margin: 5px;
  opacity: 1;
}

.video-control-btn.red-bg-btn {
  background-color: rgb(235, 87, 87);
  color: white;
  border: 1px solid transparent;
}

.video-control-btn.no-border {
  border: 1px solid transparent;
}

.video-control-btn.disabled:focus,
.video-control-btn.disabled:hover,
.video-control-btn.disabled:focus:hover,
.video-control-btn.disabled {
  background-color: red;
  color: white;
}

.video-control-btn.sharing {
  background-color: rgb(3, 190, 210);
  color: white;
}

.video-control-btn.exit-btn {
  color: red;
}

.video-control-btn.locked {
  background-color: rgb(224, 224, 224);
  color: rgb(189, 189, 189);
  cursor: not-allowed;
}

.video-control-text-btn {
  color: red;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 20px;
}

.ag-btn:hover {
  color: #00B6ED;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #637C8E;
  cursor: not-allowed;
  border: 1px solid #637C8E;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}

.ag-btn.btn-participant .MuiSvgIcon-root{
  opacity: 1;
  font-size: 24px;
}

.participant-list {
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  font-size: 12px;
  padding-top: 10px;
  height: calc(100% - 15px);
  width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
}

.participant-list.active {
  width: 300px;
}

.participant-list-cancel {
  position: absolute;
  top: 0;
  right: 0;
}

.video-header-participant {
  width: 165px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.video-header-timer-block {
  width: 218px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.participant-item {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  height: 42px;
  transition: 0.3s;
  overflow-y: hidden;
}

.participant-item.online {
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  margin-bottom: 10px;
}

.participant-item.active {
  background-color: #E6E6E6;
  height: 90px;
}

.participant-status {
  display: flex;
  justify-content: space-between;
  width: 40px;
  align-items: center;
  padding-right: 5px;
}

.participant-status-icon {
  color: black;
}

.participant-list-wrap {
  height: calc(100% - 45px);
  overflow: hidden;
}

.participant-status-icon.disable {
  color: red;
}

.participant-item-actions {
  display: none;
  justify-content: center;
  line-height: 30px;
  margin-top: 5px;
}

.participant-item.active .participant-item-actions {
  display: flex;
}

.participant-item-title {
  font-weight: 500;
  line-height: 26px;
  font-size: 24px;
  color: #666666;
  margin-top: 0;
  margin-bottom: 11px;
  display: flex;
  justify-content: flex-end;
}

.participant-item-sub-title {
  font-weight: 500;
  font-size: 14px;
  color: rgb(130, 130, 130);
}

.participant-item-row {
  display: flex;
}

.participant-avatar {
  margin-right: 10px;
}

.participant-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%; 
}

.participant-avatar.custom-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.participant-title {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
}

.ag-btn.btn-participant {
  opacity: 1;
  padding: 0 5px;
  cursor: pointer;
  font-size: 16px;
}

.host-loading {
  position: absolute;
  top: 30%;
  width: 100%;
  color: white;
  text-align: center;
}

.host-loading-timer {
  font-size: 32px;
}

.host-loading-title {
  font-size: 24px;
}

.video-timer {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  text-align: left;
  z-index: 4;
  background: rgba(18, 74, 99, .8);
  padding: 14px;
  display: flex;
}

.timer-snacbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.video-timer h6 {
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.main-screen {
  display: flex;
  height: 100%;
}

.main-screen.column-1 {
  width: calc(100% - 218px);
}

.main-screen.column-2 {
  width: calc(100% - 436px);
}

.main-screen.column-3 {
  width: calc(100% - 654px);
}

.main-screen.main-screen-full {
  width: 100%;
}

.attende-screen {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 218px;
  height: 123px;
}

.attende-screen.video-on {
  display: flex;
}

.attende-screen.video-off {
  display: none;
}

.host-attende-screen {
  display: flex;
  width: 100%;
  height: 123px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.host-attende-screen.hide-dom {
  display: none;
  height: 0;
}

.attende-list.show-arrows:hover .list-arrow-container,
.grid-container:hover .list-arrow-container  {
  display: flex;
}

.attende-list.show-split-arrows:hover .split-arrows {
  display: flex;
}


.list-arrow-container {
  position: absolute;
  display: none;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  z-index: 20;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .6);
}

.list-arrow-container.top-position,
.list-arrow-container.bottom-position {
  height: 35px;
  left: 0;
  right: 0;
  width: 80px;
  margin: 10px auto;
  border-radius: 6px;
}

.list-arrow-container.top-position {
  top: 0;
}

.list-arrow-container.bottom-position {
  bottom: 110px;
}

.list-arrow-vertical-container {
  position: absolute;
  height: 200px;
  width: 35px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  flex-direction: column;
}

.list-arrow-vertical-container.left-side {
  left: 0;
}

.list-arrow-vertical-container.right-side {
  right: 0;
}

.list-arrow-container.left-position {
  position: relative;
  height: 90px;
  width: 35px;
  border-radius: 6px;
}

.list-arrow-container:hover {
  background-color: #333;
}

.list-top-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 10px 15px;
  border-color: transparent transparent white transparent;
}

.list-bottom-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 0 15px;
  border-color: white transparent transparent transparent;
}

.list-left-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 15px 0;
  border-color: transparent white transparent transparent;
}

.list-right-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent white;
}

.list-left-arrow:hover {
  border-right-color: white;
}

.list-right-arrow:hover {
  border-left-color: white;
}

.attende-list {
  position: relative;
  overflow: hidden;
  display: none;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0;
  margin-top: 47px;
  background-color: black;
  align-self: flex-start;
  height: 100%;
}

.attende-list.attende-list-show {
  display: flex;
}

.attende-list.attende-list-show.column-1 {
  width: 218px;
}

.attende-list.attende-list-show.column-2 {
  width: 436px;
}

.attende-list.attende-list-show.column-3 {
  width: 654px;
}

.attendee-list-grid {
  position: absolute;
  right: 20px;
  display: flex;
}

.attendee-list-grid div{
  padding: 5px 10px;
  color: white;
  font-weight: 500;
}

.attende-list.attende-list-hide {
  display: none;
  width: 0;
}

.attende-list.hide-dom {
  display: none;
}

.attendes-screens-container {
  display: flex;
  position: relative;
  width: 218px;
  height: 123px;
}

.attendes-screens-container.hide-container {
  display: none;
  width: 0;
  height: 0;
}

.host-cutain-in-button-container {
  display: flex;
  position: absolute;
  width: 100%;
  top: 50%;
  justify-content: center;
}

.host-cutain-in-button {
  display: flex;
  flex-direction: column;
  z-index: 3;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.audio-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 14px;
  color: white;
  z-index: 10;
  border-radius: 50%;
  background-color: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-icon.disable {
  background-color: red;
}

.attende-screen-icon {
  color: white;
  font-size: 36px;
  display: flex;
  align-items: center;
}

.attende-screen-name {
  position: absolute;
  left: 10px;
  bottom: 5px;
  color: white;
  font-size: 14px;
  z-index: 10;
  font-weight: 500;
}

.attendes-screens,
.attendes-screens-holder {
  display: flex;
  width: 100%;
  height: 123px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attendes-screens.video-hide {
  display: none;
}

.attendes-screens-holder {
  background-color: rgb(63, 63, 63);
}

.attendes-screens-holder.bg-transparent {
  background-color: transparent;
}

.attendes-screens-holder.bg-transparent .attendes-screens-holder-title{
  color: black;
}

.attendes-screens-holder-title {
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
}

.attendes-screens-holder-image {
  width: 60px;
  height: 60px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 50%;
}

.attendes-screens-holder-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.attendes-screens-holder-image.custom-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: gray;
  color: #FFFFFF;
  font-size: 32px;
  align-items: center;
  font-weight: 500;
}

.attendes-screens {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.presenting-custom-icon {
  background-color:rgb(3, 190, 210);
  border: 1px solid rgb(3, 190, 210);
  color: white;
  border-radius: 50%;
  padding: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.video-control-present-active {
  background-color:rgb(3, 190, 210) !important;
  color: white !important;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-view {
  justify-content: center;
}

.reflex-container {
  height: calc(100% - 55px);
}

.reflex-container > .reflex-splitter {
  width: 100%;
  height: 5px;
  background-color: #F5F5F5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFCAMAAABl/6zIAAAABlBMVEUAAADMzMzIT8AyAAAAAXRSTlMAQObYZgAAABRJREFUeAFjYGRkwIMJSeMHlBkOABP7AEGzSuPKAAAAAElFTkSuQmCC);
  cursor: ns-resize;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 0;
}

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: #F5F5F5;
}

.attende-list-sub-title {
  background-color: #e6e6e6;
  padding: 3px 20px;
  font-weight: 500;
  font-size: 14px;
  color: gray;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
}

.attendee-list-scroll {
  height: calc(100% - 30px);
  overflow-y: auto
}

.video-host-control {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 1000;
}

.video-host-control .video-control-btn {
  margin-bottom: 10px;
}

.video-control-btn-label {
  position: absolute;
  z-index: 10000;
  font-size: 12px;
  font-weight: 700;
  left: 16px;
  top: 14px;
  color: #FFFFFF;
}

.video-control-btn-label.active {
  color: #000000;
}

.video-loading-icon g circle {
  fill: #000000;
}

.video-loading-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}