.str-chat__message-livestream {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.str-chat__message-livestream-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}
.str-chat__message-livestream-author {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */
  color: rgba(44, 44, 48, 0.65);
  margin-bottom: 4px;
}
.str-chat__message-livestream-author strong {
  font-weight: normal;
}
.str-chat.dark .str-chat__message-livestream-author strong {
  font-weight: normal;
  color: #ffffff65;
}

.str-chat__message-livestream-content {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  padding: 5px 10px 5px 0;
  color: #2c2c30;
}
.str-chat.dark .str-chat__message-livestream-content {
  color: #ffffff;
}

.str-chat__avatar {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}

.str-chat__date-separator {
  display: none;
}
.live-event-message__container__pinned {
  background: rgba(255, 199, 0, 0.15);
}

.pinned-messages__container .live-event-message__container__pinned {
  background: #f1faff;
}

.pin-icon {
  margin-left: 60px !important;
}
.pinned-messages__container .pin-icon .pin-text {
  display: none;
}

/* User Online Icon  */

.str-chat__message-livestream-left {
  position: relative;
}

.online-indicator {
  position: relative;
  top: 22px;
  right: -38px;
  z-index: 9;
  height: 0px;
}

#root
  > div
  > div.main-container
  > div
  > div.main-container-inner__right
  > div
  > div
  > div
  > div.str-chat__thread-list
  > div.live-event-message__container__unpinned
  > div:nth-child(3)
  > div.online-indicator {
  top: 40px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div.str-chat__thread-list
  > div.live-event-message__container__unpinned
  > div:nth-child(3)
  > div.online-indicator {
  top: 40px;
}

.live-event-message__container__pinned .str-chat__message-livestream:hover {
  background: rgba(255, 199, 0, 0) !important;
}

.dark.str-chat .str-chat__message-actions-box button:hover {
  color: black;
}

.live-event-message__container__unpinned {
  padding-right: 30px;
}
.live-event-message__container__pinned {
  padding-right: 30px;
}

/* ----------------------------------------- Message Actions ----------------------------------------- */

.new-actions .str-chat__message-simple__actions__action--options {
  height: 15px;
  width: 15px;
  justify-content: center;
  position: absolute;
  left: 276px;
  top: -6px;
  z-index: 10;
}
.new-actions .str-chat__message-simple__actions__action--options svg {
  opacity: 0;
}

.new-actions .str-chat__message-simple__actions__action--options:hover svg {
  opacity: 1;
}
.pinned-messages__container .new-actions .str-chat__message-livestream-actions {
  display: none !important;
}
.pinned-messages__container .str-chat__message-livestream-actions {
  display: none !important;
}

.pinned-messages__container .str-chat__li:hover .new-actions {
  display: none !important;
}

.str-chat.dark .new-actions .str-chat__message-simple__actions__action--options:hover svg {
  fill: white;
}

.str-chat__list--thread .new-actions .str-chat__message-simple__actions__action--options {
  height: 15px;
  width: 15px;
  justify-content: center;
  position: absolute;
  left: 271px;
  top: -6px;
  z-index: 10;
}

.new-actions .str-chat__message-actions-box {
  position: absolute;
  display: none;
  bottom: -108px;
  left: -97px;
  width: 120px;
  background: #fff;
  border-radius: 0px;
  background-image: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(0, 0, 0, 0.02) 100%
  );
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 1px 0 0 rgba(0, 0, 0, 0.08),
    0 1px 8px 0 rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.str-chat__list--thread .new-actions .str-chat__message-actions-box {
  position: absolute;
  display: none;
  bottom: -73px;
  left: -92px;
  width: 120px;
  background: #fff;
  border-radius: 0px;
  background-image: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(0, 0, 0, 0.02) 100%
  );
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 1px 0 0 rgba(0, 0, 0, 0.08),
    0 1px 8px 0 rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.livestream.str-chat.dark .str-chat__li:hover .str-chat__message-livestream-actions {
  background: #000000;
  border: none;
  box-shadow: 0px 3px 8px rgba(255, 255, 255, 0.15);
}

.new-actions .str-chat__message-actions-box--open {
  display: block;
  border-radius: 8px;
  bottom: -42px;
}

.new-actions .str-chat__message-actions-box--open.str-chat__message-actions-box--mine {
  display: block;
  border-radius: 8px;
  bottom: -108px;
}

.str-chat__list--thread .new-actions .str-chat__message-actions-box--open {
  display: block;
  border-radius: 8px;
}

.str-chat__message-simple__actions__action {
  margin: 0px;
}
.str-chat__thread-list .str-chat__list .str-chat__message-livestream:hover {
  box-shadow: none;
  border-radius: 0px;
}
.str-chat__message-livestream--initial-message:hover {
  min-height: 79px;
  width: var(--chat-width);
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  background-color: #f1faff;
  margin-left: 0px;
}

.livestream.str-chat .str-chat__list {
  width: var(--chat-width);
}

.str-chat__square-button {
  border: 0px;
  background-color: white;
}
.str-chat__square-button:focus {
  outline: 0 !important;
  background-color: white;
}

/* ----------------------------------------- Message Editing ----------------------------------------- */

.str-chat__message-team--editing {
  border: none;
  box-shadow: none;
}

.str-chat__edit-message-form .str-chat__textarea {
  width: 238px;
  height: 40px;
}
.str-chat__edit-message-form button {
  display: none;
}
.str-chat__message-team--editing .str-chat__message-team-form-footer {
  display: none;
}

.str-chat__message-team--editing .str-chat__message-team-meta {
  height: 30px;
  width: 30px;
}
.str-chat__message-team--editing .new-actions .livestream.str-chat .str-chat__li:hover {
  display: none;
}

#root
  > div
  > div.main-container
  > div
  > div.main-container-inner__right
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.str-chat__list
  > div
  > ul
  > li:nth-child(80)
  > div
  > div:nth-child(3)
  > div.str-chat__message-team.str-chat__message-team--single.str-chat__message-team--editing
  > div.str-chat__message-team-meta
  > div {
  height: 32px !important ;
  width: 32px !important;
  position: relative;
  flex-basis: 32px !important;
  margin-right: 5px;
  line-height: 32px !important;
  font-size: 15px !important;
}

/* ----------------------------------------- Tool Tip Popup ----------------------------------------- */

.str-chat__simple-reactions-list-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 100%;
  z-index: 10;
}

/* ----------------------------------------- Emoji Reaction Selector ----------------------------------------- */

.str-chat__message-livestream .str-chat__reaction-selector {
  top: -52px;
  right: -12px;
  max-height: 40px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #bed5e4;
}

.str-chat__message-livestream .str-chat__reaction-selector ul {
  margin: 0 8px;
}

.str-chat.dark .str-chat__message-livestream .str-chat__reaction-selector {
  background: black;
}

.str-chat__message-livestream .str-chat__reaction-selector .latest-user {
  display: none;
}
.str-chat__message-reactions-list-item__count {
  display: none;
}
