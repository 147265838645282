.vidiolo-video-player-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.vidiolo-video-player-container .small-video {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 140px;
  height: 140px;
}

.vidiolo-video-player-container .vidiolo-video-player-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.4s;
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}

.vidiolo-video-player-container:hover .vidiolo-video-player-controls,
.vidiolo-video-player-container.show-controls .vidiolo-video-player-controls {
  opacity: 1;
}

.vidiolo-video-player-container .control-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.vidiolo-video-player-container .vidiolo-video-player-screen {
  width: 100%;
  height: 100%;
}

.vidiolo-video-player-container .volume-control {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}

.volume-control-volume-icon {
  width: 30px;
}

.vidiolo-video-player-container .vidiolo-video-player-controls button {
  background: transparent;
  color: #fff;
  font-weight: bolder;
  text-shadow: 2px 1px 2px #000;
  border: none;
  cursor: pointer;
}
.vidiolo-video-player-container .vidiolo-video-player-controls .timeline {
  flex: 1;
  display: flex;
  align-items: center;
  border: none;
  padding: 0 10px;
}
.vidiolo-video-player-container .vidiolo-video-player-controls .timeline .bar{
  background: rgb(1, 1, 65);
  height: 4px;
  flex: 1;
}
.vidiolo-video-player-container .vidiolo-video-player-controls .timeline .bar .inner{
  background: #ccc;
  width: 0%;
  height: 100%;
}
.fa {
  font-size: 20px !important;
}