.livestream.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding: 0px;
  width: var(--chat-width);
  /* height: calc(100vh - 216px); */
}

.str-chat__thread .livestream.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding: 0px;
  width: var(--chat-width);
  height: 100%;
}

.pinned-messages__container .str-chat__reverse-infinite-scroll {
  padding-top: 0px;
  width: var(--chat-width);
  /* height: calc(100vh - 90px); */
}
.pinned-messages__container {
  padding-top: 0px;
  width: var(--chat-width);
  /* height: calc(100vh - 90px); */
}

.pinned-messages__container .pin-icon {
  display: none;
}
.str-chat.dark .pinned-messages__container {
  background: #1a1a1a;
}
.str-chat.dark .pinned-messages__container .str-chat__reverse-infinite-scroll {
  background: #1a1a1a;
}
