:root {
  --chat-width : 100%;
  --xs-p: 20px;
}

.main-container {
  height: calc(100% - 40px);
}

.str-chat {
  height: 100%;
}

.livestream.str-chat .str-chat__list {
  padding-top: 10px;
}
