.video-cv-navigation {
  padding: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-left: 5px solid transparent;
}

.vidiolo-video-content {
  height: 495px;
  overflow: hidden;
}

.vidiolo-video-player {
  position: relative;
  height: 495px;
}

.vidiolo-video-container {
  display: flex;

}

.vidiolo-video-cards {
  background-color: #FFFFFF;
  padding-left: 0;
  padding-right: 0;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  max-height: 840px;
}

.vidiolo-video-cards-mobile {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow-y: auto;
}

.vidiolo-video-cards-mobile-icon {
  font-size: 26px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vidiolo-video-cards-mobile-icon.active {
  background-color: #2D9CDB;
}

@media screen and (min-width: 1200px) { 
  .vidiolo-video-content {
    width: 385px;
  }

  .vidiolo-video-player {
    width: 495px;
  }

  .vidiolo-video-container {
    flex-direction: row;
  }

  .video-cv-user-info {
    width: 100%;
  }

  .vidiolo-video-cards {
    height: 495px;
  }

  .video-cv-user-info-content {
    display: flex;
  }

  .video-cv-user-info-avatar img {
    height: 60px;
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  .vidiolo-video-content {
    width: 495px;
  }

  .vidiolo-video-player {
    width: 100%;
  }

  .vidiolo-video-container {
    flex-direction: column;
  }
  
  .video-cv-user-info {
    width: 100%;
  }

  .video-cv-user-info-avatar img {
    height: 60px;
  }
}

@media (max-width: 800px) {
  .vidiolo-video-content {
    width: 100%;
  }

  .vidiolo-video-player {
    width: 100%;
    height: 100%;
  }

  .vidiolo-video-container {
    flex-direction: column;
  }

  .vidiolo-video-cards {
    display: none;
  }

  .vidiolo-video-cards-mobile {
    display: flex;
  }

  .video-cv-user-info {
    max-width: 480px;
  }

  .video-cv-user-info-avatar img {
    height: 60px;
  }
}

@media (max-width: 500px) {
  .vidiolo-video-content {
    width: 100%;
  }

  .vidiolo-video-player {
    width: 100%;
  }

  .vidiolo-video-container {
    flex-direction: column;
  }

  .vidiolo-video-cards {
    display: none;
  }

  .vidiolo-video-cards-mobile {
    display: flex;
  }
  
  .video-cv-user-info-content {
    display: none;
  }

  .video-cv-user-info-avatar {
    padding-left: 10px;
  }

  .video-cv-user-info-avatar img {
    height: 40px;
  }

  .video-cv-user-info-avatar h3 {
    font-size: 16px;
    font-weight: 500;
  }
}

.video-cv-navigation.active {
  border-left: 5px solid #2D9CDB;
}

.video-cv-navigation.content-header {
  cursor: auto;
}

.video-cv-navigation:hover:not(.content-header) {
  background-color: #F2F2F2;
}

.video-cv-title-container {
  display: flex;
}

.video-cv-title-icon {
  margin-right: 10px;
}

.video-cv-title-text {
  font-weight: 500;
}

.font-size-xs {
  font-size: 14px;
}

.v-primary-color {
  color: #03BED2;
}

.v-secondary-color {
  color: #999999;
}

.video-cv-user-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.video-cv-user-info-avatar {
  display: flex;
  align-items: "center";
}

.video-cv-user-info-content {
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
