.multiline-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.event-item {
  height: 390px !important;
}

.event-item-img {
  overflow: hidden;
  background-size: cover;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 180px;
}

.event-properties {
  position: absolute;
  bottom: 20px;
  padding-right: 20px;
}

.event-item-badge {
  font-size: 14px !important;
}

.event-item-badge.price {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.event-item-date {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  padding: 0 10px;
  text-shadow: 1px 1px 3px #000000;
}