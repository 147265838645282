.vidiolo-logo {
  height: 30px;
}

.vidiolo-logo path {
  fill: #03BED2;
}

.vidiolo-logo.vidiolo-logo-orginal path {
  fill: #03BED2;
}

.vidiolo-layout {
  height: 100%;
}

.vidiolo-loading {
  position: absolute;
  top: 400px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 5;
  flex-direction: column;
}